<template>
  <div class="contract">
    <Banner :list="banners"></Banner>
    <div class="contract-main container">
	  <menus></menus>
      <div class="serve">
        <div class="main-title">
          <h1>总承包资质服务</h1>
          <h3>业务范围覆盖全国 7*24小时无忧服务</h3>
        </div>
        <div class="list">
          <template v-for="(item, index) in childList">
            <div class="item mb-2 p-1 text-hide" :key="index" @click="navView(item.skip_url)">
              <div class="img-box">
                <img :src="item.image_url" alt="" />
              </div>
              <p class="name mt-1">{{ item.name }}</p>
            </div>
          </template>
        </div>
      </div>
      <connect></connect>
	 
	  <!-- 快速通道 -->
	   <passage></passage>
    </div>

	<certificate></certificate>
	
	<videolist ifcolor="color" :fromData="fromData" ></videolist>
	
	<!-- 滚动 + 留言 -->
	<strengthnew :fromType="fromType"></strengthnew>
	
	<!-- tab 选项卡 -->
	<choose></choose>

	<!-- 新闻 -->
    <news ifcolor="color"></news>
	<!-- 实力见证 -->
	<div class="container">
		<strength></strength>
	</div>
	<!-- <message></message> -->
  </div>
</template>

<script>
import Banner from "@/components/common/banner";
import choose from "@/components/common/choose";
import passage from "@/components/common/passage";

import message from "@/components/common/message";
import connect from "@/components/common/connect";
import { WOW } from "wowjs";
export default {
  components: {
    Banner,
    passage,
    message,
    choose,
    connect,
  },
  data() {
    return {
	  fromType:10,
	  fromData:{subdoadmin: '',launchPage: 20,classify: 20,scope: 0},
      banners: {
        title: "12项施工总承包资质办理",
        desc: "快捷便利无忧 服务专业优质",
        list: [require("@/assets/image/banner/1.jpg")],
      },
      comments: [
        {
          icon: require("@/assets/image/contract/1.jpg"),
          name: " 王先生",
          company: "天津市***市政工程有限公司",
          desc: "提前两月拿到证书，进度及时汇报，不错",
          date: "2020-01-21",
        },
        {
          icon: require("@/assets/image/contract/2.jpg"),
          name: "陈先生",
          company: "北京市****（北京）科技有限公司",
          desc: "收费透明，不乱收费；申报人员问题反馈也很及时",
          date: "2020-05-23",
        },
        {
          icon: require("@/assets/image/contract/3.jpg"),
          name: "刘先生",
          company: "杭州市****劳务有限公司",
          desc:
            "6月找同行办理机电总包资质，8月份同行告知办不下来，经人介绍找到优普道，两个月就办理成功了。",
          date: "2020-06-07",
        },
        {
          icon: require("@/assets/image/contract/4.jpg"),
          name: "魏先生",
          company: "西安市**安建建筑工程有限公司",
          desc: "感觉挺不错的，全程都不用我们操心，一站式的代办，太省心了",
          date: "2020-03-18",
        },
        {
          icon: require("@/assets/image/contract/5.jpg"),
          name: "唐先生",
          company: "南昌市**电力工程建设有限公司",
          desc: "这家收费算比较符合行业标价，收费透明，效率也高，合作挺愉快的",
          date: "2020-4-23",
        },
        {
          icon: require("@/assets/image/contract/6.jpg"),
          name: "吴先生",
          company: "哈尔滨****建筑工程有限公司",
          desc:
            "多家对比后选择了优普道公司，专人定制的服务、实力雄厚的展现，办理速度提前下证，五星好评！",
          date: "2020-1-06",
        },
        {
          icon: require("@/assets/image/contract/7.jpg"),
          name: "王先生",
          company: "大庆市***建筑工程有限公司",
          desc: "办理过程很顺利，资质和安全生产许可证按期拿到。",
          date: "2020-07-13",
        },
        {
          icon: require("@/assets/image/contract/8.jpg"),
          name: "吴总",
          company: "海南**能源发展有限公司",
          desc: "给优普道点赞，办理效率很高，很有实力！",
          date: "2020-10-08",
        },
        {
          icon: require("@/assets/image/contract/9.jpg"),
          name: "吴先生",
          company: "广西峻宇建筑工程有限责任公司",
          desc: "办理资质这个公司比较靠谱。下证快，服务态度好。",
          date: "2020-11-11",
        },
        {
          icon: require("@/assets/image/contract/10.jpg"),
          name: "林先生",
          company: "福建**禾通信有限公司 ",
          desc:
            "多种办理方案供我选择，这服务没得说，建议我怎么办理的性价比最高，赞。",
          date: "2020-09-07",
        },
        {
          icon: require("@/assets/image/contract/11.jpg"),
          name: " 谢先生",
          company: "通**通信股份有限公司",
          desc: "办理人员专业，耐心，有不懂的地方给我说的明明白白，找对地方了",
          date: "2020-08-21",
        },
        {
          icon: require("@/assets/image/contract/12.jpg"),
          name: "李生",
          company: "茂名***建设工程有限公司",
          desc: "可以办理资质也可以找人才，不用自己到处找，大力推荐",
          date: "2020-09-06",
        },
        {
          icon: require("@/assets/image/contract/13.jpg"),
          name: "陈生",
          company: "广州**绿化工程有限公司",
          desc: "缺少的人才也是这补全的，完全没有延误办理周期",
          date: "2020-07-02",
        },
        {
          icon: require("@/assets/image/contract/14.jpg"),
          name: "简女士",
          company: "珠海**机械设施工程管理有限公司",
          desc:
            "办事效率高，办理的人员非常专业，申报前反复核对资料，确保我一次过，提前拿证",
          date: "2020-05-20",
        },
        {
          icon: require("@/assets/image/contract/15.jpg"),
          name: "吴生",
          company: "深圳**建设工程有限公司",
          desc: "办理顺利，一次通过，需要增项资质再合作！",
          date: "2020-01-21",
        },
        {
          icon: require("@/assets/image/contract/16.jpg"),
          name: "张生",
          company: "深圳**建筑设计工程有限公司",
          desc: "给优普道点个赞，办事效率高，员工专业服务好！",
          date: "2019-04-20",
        },
        {
          icon: require("@/assets/image/contract/17.jpg"),
          name: "王工",
          company: "广州**工程有限公司",
          desc: "服务到位，跟进及时，取证很顺利",
          date: "2019-05-18",
        },
        {
          icon: require("@/assets/image/contract/18.jpg"),
          name: "邓生",
          company: "佛山**星建设工程公司",
          desc:
            "报价合理透明，一对一服务跟进及时，拿证很顺利，很不错的一次合作",
          date: "2019-10-20",
        },
        {
          icon: require("@/assets/image/contract/19.jpg"),
          name: " 郑女士",
          company: "珠海**工程管理有限公司 ",
          desc: "5个资质一起办理，不到4个月便拿证了。 ",
          date: "2019-06-29",
        },
      ],
      questions: [
        {
          title: "资质怎么办理？",
          desc:
            "您是做什么工程的呢，资质的类别有很多项，每个资质的办理条件都是不一样的，得根据您的工程去给您匹配符合您所需资质的，比方说您做装修就有专业的装修装饰类资质，做地基的就要地基基础专业承包资质；资质办理需要符合资质标准的要求，准备齐全人员、资料等，在对应审批部门申报",
        },
        {
          title: "办一个资质多少钱？",
          desc:
            "办理资质需要根据您公司的人员配备情况，企业的基本条件是否符合以及您需要办理什么样的资质去决定您公司本次办理的费用多少的，比方说您需要办理一个施工总包资质，我们的代办费用是8万（双部门审批及特殊资质除外），办理一个施工专业承包资质，我们代办费6万（双部门审批及特殊资质除外），如果您公司没有或不齐办理资质所需的建造师、工程师及技术工人等资质办理所需配备，那么就要根据您的企业情况去做详细核算，给您制定报价方案",
        },
        {
          title: "办理一个资质需要多久？",
          desc:
            "办理资质需要根据您公司的人员配备情况，企业的基本条件是否符合以及您需要办理什么样的资质去决定您公司本次办理的费用多少的，比方说您需要办理一个施工总包资质，我们的代办费用是8万（双部门审批及特殊资质除外），办理一个施工专业承包资质，我们代办费6万（双部门审批及特殊资质除外），如果您公司没有或不齐办理资质所需的建造师、工程师及技术工人等资质办理所需配备，那么就要根据您的企业情况去做详细核算，给您制定报价方案",
        },
        {
          title: "办资质我能接什么工程？",
          desc:
            "每个资质办理的条件都不一样，您首先需要有一家公司作为申报主体，符合注册资金、注册地址、经营范围的要求，其次就是建造师、工程师、技术工人（特殊资质需要设备发票等）；不具备条件的公司，咱们也可以帮您去处理，都是根据您的具体情况去给您量身制定方案的",
        },
      ],
      serverList: [
        {
          name: "建筑工程施工总承包",
          icon: require("@/assets/image/contract/37.png"),
        },
        {
          name: "铁路工程施工总承包",
          icon: require("@/assets/image/contract/38.png"),
        },
        {
          name: "公路工程施工总承包",
          icon: require("@/assets/image/contract/39.png"),
        },
        {
          name: "港口与航道工程施工总承包",
          icon: require("@/assets/image/contract/40.png"),
        },
        {
          name: "水利水电工程施工总承包",
          icon: require("@/assets/image/contract/41.png"),
        },
        {
          name: "电力工程施工总承包",
          icon: require("@/assets/image/contract/42.png"),
        },
        {
          name: "市政公用工程施工总承包",
          icon: require("@/assets/image/contract/43.png"),
        },
        {
          name: "通信工程施工总承包",
          icon: require("@/assets/image/contract/44.png"),
        },
        {
          name: "机电工程施工总承包",
          icon: require("@/assets/image/contract/45.png"),
        },
        {
          name: "矿山工程施工总承包",
          icon: require("@/assets/image/contract/46.png"),
        },
        {
          name: "石油化工工程施工总承包",
          icon: require("@/assets/image/contract/47.png"),
        },
        {
          name: "冶金工程施工总承包",
          icon: require("@/assets/image/contract/48.png"),
        },
      ],
      childList: [],
    };
  },
  methods: {
    getBannerList() {
      this.$api
        .getBannerList({
          // 10首页  20
          launchPage: 20,
          launchFacility: 10,
        })
        .then((res) => {
          this.banners.list = res.data.list;
        });
    },
    navView(url) {
      if (this.$ios) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    },
    getCategoryChild() {
      this.$api
        .getCategoryChild({
          pid: 91,
        })
        .then((res) => {
          this.childList = res.data;
        });
    },
  },
  created() {
    this.getBannerList();
  },
  mounted() {
    this.getCategoryChild();
    var wow = new WOW({
      boxClass: "wow",
      animateClass: "animated",
      offset: 0,
      mobile: true,
      live: true,
    });
    wow.init();
    // this.initStrenghSwiper();
  },
};
</script>

<style lang="scss">

@import "@/assets/css/contract.scss";
@font-face {
  font-family: "HYYaKuHeiW";
  src: url(../../assets/font/HYYaKuHeiW.ttf);
  font-weight: normal;
  font-style: normal;
}

</style>
